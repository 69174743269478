
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}

.liveMarketScore {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .sureBtn {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 10px 20px;
        box-sizing: border-box;
    }

    .content1 {
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        padding: 0 10px;
        box-sizing: border-box;
        font-size: 16px;
        margin-bottom: 10px;

        .left {
            width: 509px;
            margin-right: 20px;
        }

        .right {
            flex: 1;
        }
    }

    .content {
        width: 100%;
        height: 660px;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        padding: 0 10px;
        box-sizing: border-box;
        font-size: 16px;
        margin-bottom: 20px;

        .left {
            width: 509px;
            background-color: #fff;
            margin-right: 20px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            padding: 20px;
            box-sizing: border-box;

            .left_top {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                //margin-top: 8px;

                .left-top-border-box {
                    height: 100%;
                    width: 2px;
                    background: #F3F3F3;
                    margin-left: 5px;
                    margin-right: 15px;
                    position: absolute;
                    left: 60px;
                    z-index: 1
                }

                .left_top_left {
                    width: 60px;
                }

                .left_top_center {
                    width: 12px;
                    height: 12px;
                    background: #D9D9D9;
                    border-radius: 6px;
                    margin-right: 10px;
                }

                .left_top_right {
                    flex: 1;
                    color: #666666;
                    padding-top: 8px;
                }
            }

            .left_bottom {
                display: flex;
                flex-direction: row;

                .left_bottom_left {
                    width: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    color: #666666;

                }

                .left_bottom_center {
                    width: 2px;
                    background: #F3F3F3;
                    margin-left: 5px;
                    margin-right: 15px;
                }

                .left_bottom_right {
                    flex: 1;
                    padding: 12px;
                    box-sizing: border-box;
                    background-color: #FAFAFA;
                    margin-top: 8px;
                    margin-bottom: 8px;
                    font-size: 14px;
                }
            }
        }

        .right {
            flex: 1;
            display: flex;
            flex-direction: row;
            background-color: #fff;
            border-radius: 8px;
            position: relative;

            .score_btn {
                margin-top: 150px;
            }

            .video-box {
                width: 363px;
                height: 660px;
                background-color: #0C0E3F;
                border-radius: 8px;
                position: absolute;
                top: 0;
                overflow: hidden;
            }
        }
    }
}
.no-data {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #999;
  text-align: center;
}
.content-box:first-child .left_top .left-top-border-box {
    opacity: 0;
}
.content-box:last-child .left_bottom .left_bottom_center {
    opacity: 0;
}
